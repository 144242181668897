<template>
    <CameraScanner />
</template>

<script>
import CameraScanner from '@/components/barcodeScanner/CameraScanner.vue'

export default {
  components: {
    CameraScanner
  }
}
</script>