import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import DashboardPage from "@/pages/dashboard-page.vue"

import NotFoundPage from "@/pages/not-found-page.vue"
import CallbackPage from "@/components/auth/CallBack.vue"
import ProfilePage from "@/pages/profile-page.vue"
import ScannerPage from "@/pages/barcode-page.vue"
import BeaconStationsPage from "@/pages/beacon-station/beacon-station-page.vue"
import newBeaconStationsPage from "@/pages/beacon-station/new-beacon-station-page.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: DashboardPage
  },
  {
    path: '/overview',
    name: 'overview',
    component: DashboardPage,
  },
  {
    path: '/beacon-stations',
    name: 'beacon-stations',
    component: BeaconStationsPage,
  },
  {
    path: '/new-beacon-stations',
    name: 'new-beacon-stations',
    component: newBeaconStationsPage,
  },
  {
    path: '/barcode',
    name: 'barcode',
    component: ScannerPage,
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfilePage,
  },
  {
    path: "/callback",
    name: "callback",
    component: CallbackPage,
  },
  {
    path: "/:catchAll(.*)",
    name: "Not Found",
    component: NotFoundPage,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router