<template>
  <h2>EAN is: {{ decodedText }}</h2>
  <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
</template>

<script setup>
import { ref } from "vue";
import { StreamBarcodeReader } from "vue-barcode-reader";
const decodedText = ref("");
const onLoaded = () => {
  console.info("loaded");
};
const onDecode = (text) => {
  decodedText.value = text;
  console.info(text);
};
</script>

<style scoped>
video {
  max-width: 640px;
  max-height: 480px;
}

.scanner-container {
  position: relative;
  max-width: 640px;
  max-height: 480px;
}

.overlay-element {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 640px;
  max-height: 480px;
  background: rgba(30, 30, 30, 0.5);
  -webkit-clip-path: polygon(
    0% 0%,
    0% 100%,
    20% 100%,
    20% 20%,
    80% 20%,
    80% 80%,
    20% 80%,
    20% 100%,
    100% 100%,
    100% 0%
  );
  clip-path: polygon(
    0% 0%,
    0% 100%,
    20% 100%,
    20% 20%,
    80% 20%,
    80% 80%,
    20% 80%,
    20% 100%,
    100% 100%,
    100% 0%
  );
}

.laser {
  width: 80%;
  margin-left: 10%;
  background-color: tomato;
  height: 1px;
  position: absolute;
  top: 20%;
  z-index: 2;
  box-shadow: 0 0 4px red;
  -webkit-animation: scanning 3s infinite;
  animation: scanning 3s infinite;
}

@-webkit-keyframes scanning {
  50% {
    -webkit-transform: translateY(75px);
    transform: translateY(75px);
  }
}

@keyframes scanning {
  50% {
    -webkit-transform: translateY(75px);
    transform: translateY(75px);
  }
}
</style>
