import { defineStore } from 'pinia'

// quick and dirty bomb
const debugMode = false;

export const useSettings = defineStore('useSettings', {
  state: () => {
    return {
      isAuthenticated: debugMode,
      backendApi: process.env.VUE_APP_BACKEND,
      userEmail: null,
      userName: null,
      userAddress: null,
      userPhone: null,
      userImage: null,
      userRole: null,
      userCompany: null,
      scanningStations: [],
      scanningCollections: [
        {
          'id': 1,
          'name': 'Collection 7'
        },
      ]
    }
  },
  actions: {
    async getBeaconStations() {
      this.scanningStations = [];
      const res = await fetch(this.backendApi + "/scannertool/beacon");
      const data = await res.json();
     
      data.forEach(i => {
          this.scanningStations.push(i);
      });
    },
  }
})