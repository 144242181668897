<template>
    <h1 class="mb-4">Current stations available:</h1>
    <h3>Name:</h3>
    <input v-model="name" placeholder="" required />
    <h3>Location:</h3>
    <input v-model="location" placeholder="" required />
    <br/>
    <button class="mt-4 p-2 bg-theme-color1 rounded-lg text-center text-white hover:bg-theme-color2" @click="addRow()">Add</button>
</template>

<script>
import { useSettings } from '@/store/user'
import { storeToRefs } from 'pinia'

export default {
    setup() {
        const user = useSettings();
        user.getBeaconStations();
        const { getBeaconStations } = storeToRefs(user)

        return { getBeaconStations, user }
    },
    data() {
        return {
            name: null,
            location: null
        }
    },
    methods: {
        async addRow() {
            const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    "name": this.name,
                    "location": this.location,
                })
            };
            const response = await fetch("http://localhost:4000/scannertool/beacon/new", requestOptions);
            // eslint-disable-next-line
            const data = await response.json();
            this.$router.push('/beacon-stations');
        }
    }
}
</script>