<template>
    <h1 class="mb-4">Current stations available:</h1>
    <ul v-for="station in user.scanningStations" :key="station.id">
        <li>{{ station.name }} - {{ station.location }}</li>
    </ul>
    <button class="mt-4 p-2 bg-theme-color1 rounded-lg text-center text-white hover:bg-theme-color2"
        @click="this.$router.push('/new-beacon-stations');">Add new beacon station</button>
</template>

<script>
import { useSettings } from '@/store/user'
import { storeToRefs } from 'pinia'

export default {
    setup() {
        const user = useSettings();
        user.getBeaconStations();
        const { getBeaconStations } = storeToRefs(user)

        return { getBeaconStations, user }
    },
    methods: {
        async addRow() {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    "location": "Building 111",
                    "name": "Test xx"
                })
            };
            const response = await fetch("https://localhost:4000/scannertool/beacon/new", requestOptions);
            // eslint-disable-next-line
            const data = await response.json();
            this.$router.push('/beacon-stations');
        }
    }
}
</script>